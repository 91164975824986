require('./utils');
require('./bootstrap-navbar');
require('./detector');


require('popper.js/dist/popper.js');
require('./bootstrap');
require('is_js/is');
require('leaflet/dist/leaflet');

// /* -------------------------------------------------------------------------- */
// /*                            Theme Initialization                            */
// /* -------------------------------------------------------------------------- */
